<script setup lang="ts">
</script>

<template>
    <div class="w-full product-highlight d-print-none">
        <div class="md:grid limit-width-md mx-auto">
            <img src="/img/dynaboost-logo.png" alt="DynaBoost™ Product Logo" class="pb-5 md:pb-0 px-5 md:px-0 m-auto"/>
            <div class="px-3 md:px-0">
                <h2 class="prominent-heading">DynaBoost™ Product Sheets</h2>
                <p class="mb-7 subtitle">Download our DynaBoost™ Product Sheets</p>
                <ul class="product-sheets mb-7 list-style-none">
                    <li class="xl:inline">
                        <a
                            href="https://www.yakimachief.com/media/wysiwyg/documents/DynaBoost_Product_Data_Sheet.pdf"
                        >
                            DynaBoost™ Product Data Sheet
                        </a>
                    </li>
                    <li class="xl:inline">
                        <a
                            href="https://www.yakimachief.com/media/wysiwyg/documents/DynaBoost_Product_Overview_Sheet.pdf"
                        >
                            DynaBoost™ Overview Sheet
                        </a>
                    </li>
                    <li class="xl:inline">
                        <a
                            href="https://www.yakimachief.com/media/wysiwyg/documents/DynaBoost_Technical_One-sheet.pdf"
                        >
                            DynaBoost™ Technical One Sheet
                        </a>
                    </li>
                </ul>
                <div class="flex flex-row gap-5">
                    <a href="https://www.yakimachief.com/commercial/contact" class="btn secondary mb-3 md:mb-0">
                        Questions about DynaBoost™? Contact us!
                    </a>
                    <a href="https://www.yakimachief.com/commercial-dynaboost" class="btn primary">
                        Ready to buy DynaBoost™? Shop now!
                    </a>
                </div>
            </div>
        </div>
        <div class="dynaboost banner">
            <div class="video">
                <iframe
                    src="https://www.youtube.com/embed/GZKkI1XBd08?si=z8eSjvjHMMAswudk"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.btn {
    padding: var(--y-p-xs) var(--y-p-sm);
    border-radius: var(--y-radius-sm);
    min-width: 2rem;
    min-height: 2rem;
    gap: var(--y-gap-md);
    display: inline-flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    border: 0;
    font-family: var(--y-font-family-sans-serif);
    font-weight: var(--y-font-weight-medium);
    font-size: var(--y-font-size-md);
    cursor: pointer;


    &.primary {
        color: var(--ych-white);
        background-color: var(--ych-red);
    }

    &.secondary {
        color: var(--ych-white);
        background-color: var(--ych-blue);
    }
}

</style>
