import ProductPackageDetails from "@/types/ProductPackageDetails";

let poundsAbbreviation = 'lb'
let kilogramAbbreviation = 'kg'
let gramsAbbreviation = 'g'
let poundsDisplay = 'Pounds'
let kilogramDisplay = 'Kilograms'
let gramsDisplay = 'Kilograms'

let t90Display = 'T-90'
let cryoDisplay = 'Cryo<sup>&reg</sup>'
let dynaBoostDisplay = 'DynaBoost&trade;'
let hyperBoostDisplay = 'HyperBoost&trade;'

interface ProductDisplayMap {
    productName: string
    shouldDisplay: boolean
}

interface ConvertedValue {
    amount: number | null
    units: string
}

interface CalculatorDefaultState {
    replaceT90: boolean;
    replaceCryo: boolean;
    currentUsage?: string | undefined;
    percentageToConvert?: string | undefined;
    convertToDynaBoost: boolean;
    convertToHyperBoost: boolean;
    convertToT90: boolean;
    convertToCryo: boolean;
    replacedAlpha?: string | undefined;
    convertedAlpha?: string | undefined;
    selectedCurrency: string;
    potentialBatchSavings: null | number;
    originalProductCost: string | undefined;
    newProductCost: string | undefined;
    beerRevenuePerBbl: string | undefined;
    numberOfBatchesConverted: string;
}



function getProductPackageDetails(product: string, units: string): ProductPackageDetails {
    let productPackageDetails: ProductPackageDetails = {
        priceRange: null,
        packageSize: null,
    }

    switch (product) {
        case cryoDisplay:
            productPackageDetails.priceRange = [18, 40]
            productPackageDetails.packageSize = units;
            break;
        case t90Display:
            productPackageDetails.priceRange = [8, 17]
            productPackageDetails.packageSize = units;
            break;
        case dynaBoostDisplay:
            productPackageDetails.priceRange = [250, 375]
            productPackageDetails.packageSize = 'kg';
            break;
        case hyperBoostDisplay:
            productPackageDetails.priceRange = [250, 375]
            productPackageDetails.packageSize = '100g';
            break;
    }

    return productPackageDetails
}


function getProductPricing(product: string, units: string): string {
    let details = getProductPackageDetails(product, units);
    return details?.priceRange ? '$' + details.priceRange.join('-') + '/' + details.packageSize : '';
}

function converter(
    replacedProductLabel: string,
    convertToProductLabel: string,
    gramsToConvert: number,
    selectedMeasurement: string
): ConvertedValue {
    let convertedValue = null
    let convertedValueUnit = gramsAbbreviation

    switch (replacedProductLabel) {
        case t90Display:
            switch (convertToProductLabel) {
                case cryoDisplay:
                    convertedValue = convertT90ToCryo(gramsToConvert)
                    break
                case hyperBoostDisplay:
                    convertedValue = convertT90ToHyperBoost(gramsToConvert)
                    break
                case dynaBoostDisplay:
                    convertedValue = convertT90ToDynaBoost(gramsToConvert)
                    break
            }
            break
        case cryoDisplay:
            switch (convertToProductLabel) {
                case t90Display:
                    convertedValue = convertCryoToT90(gramsToConvert)
                    break
                case hyperBoostDisplay:
                    convertedValue = convertCryoToHyperBoost(gramsToConvert)
                    break
                case dynaBoostDisplay:
                    convertedValue = convertCryoToDynaBoost(gramsToConvert)
                    break
            }
            break
    }
    if (convertedValue !== null) {
        if (
            selectedMeasurement == 'Imperial' &&
            (convertToProductLabel === t90Display || convertToProductLabel === cryoDisplay)
        ) {
            convertedValue = Math.round(convertToPounds(convertedValue, gramsAbbreviation) * 100) / 100
            convertedValueUnit = poundsAbbreviation
        }

        if (convertedValueUnit == gramsAbbreviation && convertedValue > 1000) {
            convertedValue = convertedValue / 1000
            convertedValueUnit = kilogramAbbreviation
        }
    }

    return {
        amount: convertedValue,
        units: convertedValueUnit
    }
}


function calculateSavings(replacedProductMass: number,
                          originalProductCost: number,
                          newProductDose: number,
                          newProductCostByG: number,
                          beerRevenuePerBbl: number,
                          beerIncreaseLiters: number): number | null {

    let litersToBbl = 0.00852168;
    let beerIncreaseBbl = beerIncreaseLiters * litersToBbl;
    let potentialSavings = beerIncreaseBbl * beerRevenuePerBbl;
    let originalCost = originalProductCost * replacedProductMass;
    let newCost = newProductCostByG * newProductDose;
    let costDifference = newCost - originalCost;

    return potentialSavings - costDifference;
}

function convertToGrams(amount: number, units: string): number {
    let grams: number = 0
    if (units === kilogramAbbreviation) {
        grams = amount * 1000
    } else if (units === poundsAbbreviation) {
        grams = amount / 0.0022
    }
    return grams
}

function convertToPounds(amount: number, units: string): number {
    let pounds: number = 0
    if (units === kilogramAbbreviation) {
        pounds = amount * 2.2
    } else if (units === gramsAbbreviation) {
        pounds = amount * 0.0022
    }
    return pounds
}

function calculateAlphaReduction(reductionInGrams: number, replacedAlpha: number, convertedUsage: number, convertedAlpha: number): number {
    let alphaReduction = reductionInGrams * (replacedAlpha / 100);
    let alphaReplacement = convertedUsage * (convertedAlpha / 100);

    return Math.round((alphaReduction - alphaReplacement)/alphaReduction * 1000)/10
}

function getProductDisplayLabel(productList: Array<ProductDisplayMap>): string {
    let displayLabel = ''
    let displayProduct =
        Object.values(productList).filter((value: ProductDisplayMap) => value.shouldDisplay)[0] ?? null
    displayLabel = displayProduct ? displayProduct.productName : ''
    return displayLabel
}

function calculatePotentialBeerOutputIncrease(
    replacedProductLabel: string,
    convertToProductLabel: string,
    reductionAmountInGrams: number
): number {
    let potentialIncrease = 0
    if (convertToProductLabel == cryoDisplay) {
        let t90Reduction = (reductionAmountInGrams / 1000) * 10;
        let cryoIncrease = (reductionAmountInGrams / 1000) * 5;
        potentialIncrease = t90Reduction - cryoIncrease;

    } else {
        switch (replacedProductLabel) {
            case t90Display:
                potentialIncrease = (reductionAmountInGrams / 1000) * 10
                break
            case cryoDisplay:
                potentialIncrease = (reductionAmountInGrams / 1000) * 5
                break
        }
    }
    // Returns liters, assuming 1kg beer = 1L beer
    return Math.round(potentialIncrease)
}

function getProductClass(productName: string): string {
    let productClass = ''
    switch (productName) {
        case t90Display:
            productClass = 't90'
            break
        case cryoDisplay:
            productClass = 'cryo'
            break
        case dynaBoostDisplay:
            productClass = 'dynaboost'
            break
        case hyperBoostDisplay:
            productClass = 'hyperboost'
            break
    }
    return productClass
}

const currencyOptions = ['USD', 'EUR', 'GBP', 'CAD', 'AUD']
const currencySymbols = {
    'USD': '$',
    'EUR': '€',
    'GBP': '£',
    'CAD': 'C$',
    'RMB': '¥',
    'AUD': 'A$',
}

function convertT90ToCryo(grams: number) {
    return Math.round(grams / 2)
}

function convertCryoToT90(grams: number) {
    return Math.round(grams * 2)
}

function convertT90ToDynaBoost(grams: number) {
    return Math.round(grams / 10)
}

function convertT90ToHyperBoost(grams: number) {
    return Math.round(grams / 100)
}

function convertCryoToDynaBoost(grams: number) {
    return Math.round(grams / 5)
}

function convertCryoToHyperBoost(grams: number): number {
    return Math.round(grams / 50)
}

export {
    t90Display,
    cryoDisplay,
    dynaBoostDisplay,
    hyperBoostDisplay,
    poundsAbbreviation,
    kilogramAbbreviation,
    poundsDisplay,
    kilogramDisplay,
    currencyOptions,
    currencySymbols,
    gramsAbbreviation,
    getProductPackageDetails,
    getProductPricing,
    calculateSavings,
    calculateAlphaReduction,
    type ConvertedValue,
    type CalculatorDefaultState,
    converter,
    getProductClass,
    calculatePotentialBeerOutputIncrease,
    getProductDisplayLabel,
    convertToGrams,
    convertT90ToCryo,
    convertT90ToDynaBoost,
    convertCryoToDynaBoost,
    convertCryoToHyperBoost,
    convertT90ToHyperBoost,
    convertCryoToT90
}
