<script setup lang="ts">
import { ref } from 'vue'
import LotSearch from '@/components/LotSearch.vue'
import AnalysisMethodsModal from '@/components/AnalysisMethodsModal.vue'
import AnalysisLegendModal from '@/components/AnalysisLegendModal.vue'
import useLotStore from '@/stores/LotStore'
import CalculatorButtons from '@/components/CalculatorButtons.vue'

const lotStore = useLotStore()

const lotSearch = ref([])

const openAnalysisMethodsModal = ref(false)
const openAnalysisLegendModal = ref(false)
</script>
<template>
  <div class="min-content-height flex flex-col justify-start items-center">
    <div class="lot-lookup flex flex-col w-full gap-6 py-12 px-6 justify-center items-center">
      <h1 class="prominent-heading">Lot Analysis Lookup</h1>
      <div class="limit-width flex flex-col gap-4 justify-center w-full items-center">
        <p class="text-center text-pretty">
          Looking for specific hop analysis information for your Yakima Chief Hops purchase?<br /><span
            class="font-medium text-pretty"
          >
            Enter a lot number or, to compare, enter as a comma separated list</span
          >.
        </p>

        <lot-search placeholder="Enter Lot Number..." width="35.125rem" :fresh-search="true" />

        <p class="text-center text-pretty">
          Learn about the
          <a href="#" @click.prevent="openAnalysisMethodsModal = true">analysis methods</a> used by
          YCH laboratories and
          <a href="#" @click.prevent="openAnalysisLegendModal = true"
            >what the lot analysis results mean</a
          >.
        </p>
      </div>
    </div>

    <CalculatorButtons />
    <analysis-methods-modal v-model:open="openAnalysisMethodsModal" />
    <analysis-legend-modal v-model:open="openAnalysisLegendModal" />
  </div>
</template>
<style lang="scss" scoped>
@media screen and (max-width: 640px) {
  .header-image {
    background: url(/resources/assets/lot-lookup_header_mobile.jpg);
    height: 35rem;
  }

  .prominent-heading {
    font-size: 2.25rem;
  }

  h1 {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 641px) and (max-width: 1280px) {
  .header-image {
    background: url(/resources/assets/lot-lookup_header_tablet.jpg);
    height: 40rem;
  }
}

@media screen and (min-width: 1281px) {
  .header-image {
    background: url(/resources/assets/lot-lookup_header_desktop.jpg);
    height: 40rem;
  }
}

.lot-lookup {
  background-color: var(--ych-white);
  min-height: 450px;

  @media screen and (max-width: 640px) {
    min-height: 280px; /* Adjusted min-height for mobile */
  }
}

@media screen and (max-width: 640px) {
  .calculators__buttons__cryo,
  .calculators__buttons__ibu,
  .calculators__buttons__extract {
    width: 100%;
  }
}
</style>
