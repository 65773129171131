import {createRouter, createWebHistory} from 'vue-router'
import type VueRouter from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import LotLookupView from '@/views/LotLookupView.vue'
import CalculatorView from '@/views/CalculatorView.vue'
import ExtractConversionView from '@/views/ExtractConversionView.vue'
import IbuCalculatorView from '@/views/IbuCalculatorView.vue'
import CryoCalculator from '@/views/CryoCalculator.vue'
import EfficiencyCalculator from '@/views/EfficiencyCalculator.vue'
import FreshHopRequestForm from "@/views/FreshHopRequestForm.vue";

const routes: Readonly<VueRouter.RouteRecordRaw>[] = [
    {
        path: '/',
        name: 'YCH Brewing Tools',
        component: HomeView,
        props: {
            title: 'Lot Lookup Home'
        }
    },
    {
        path: '/lookup',
        name: 'Lot Lookup',
        component: LotLookupView
    },
    {
        path: '/table',
        name: 'Lot Lookup Table',
        component: LotLookupView
    },
    {
        path: '/calculators',
        name: 'Brewing Calculators',
        component: CalculatorView
    },
    {
        path: '/calculators/extract',
        name: 'Extract Conversion Calculator',
        component: ExtractConversionView
    },
    {
        path: '/calculators/ibu',
        name: 'IBU Calculator',
        component: IbuCalculatorView
    },
    {
        path: '/calculators/cryo',
        name: 'Cryo Calculator',
        component: CryoCalculator
    },
    {
        path: '/calculators/efficiency/:type?',
        name: 'Efficiency Calculator',
        component: EfficiencyCalculator
    },
    {
        path: '/fresh-hop',
        name: 'Fresh Hop',
        component: FreshHopRequestForm
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'FileNotFound',
        component: () => import('@/views/FileNotFoundView.vue')
    }
]
const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((toRoute, fromRoute, next) => {
    window.document.title = <string>toRoute.name;
    next();
})

export default router
