<script setup lang="ts">
import {YButton, YDropdown} from '@yakimachief/ych-ts-component-library'
import {ref, useTemplateRef} from 'vue'
import {useRoute, useRouter} from 'vue-router'
import BrewingProcessSelector from '@/components/EfficiencyCalculator/BrewingProcessSelector.vue'
import HyperBoostCalculator from '@/components/EfficiencyCalculator/HyperBoost/HyperBoostCalculator.vue'
import DynaBoostCalculator from '@/components/EfficiencyCalculator/DynaBoost/DynaBoostCalculator.vue'
import ResetCalculatorModal from '@/components/ResetCalculatorModal.vue'
import {CalculatorDefaultState} from '@/composables/efficiencyConversions'

const route = useRoute()
const router = useRouter()

const inputIsDirty = ref(false)
const formFilled = ref(false)
const measurementOptions = ['Imperial', 'Metric']
const selectedMeasurement = ref('Imperial')
const showResetModal = ref(false)

const brewingProcessSelectorIsVisible = ref(false)
const hyperBoostCalculatorIsVisible = ref(false)
const dynaBoostCalculatorIsVisible = ref(false)
const hyperBoostCalculator = useTemplateRef('hyper-boost-calculator')
const dynaBoostCalculator = useTemplateRef('dyna-boost-calculator')

const calculatorDefaultState: CalculatorDefaultState = {
    replaceT90: false,
    replaceCryo: false,
    currentUsage: undefined,
    percentageToConvert: '25',
    convertToDynaBoost: false,
    convertToHyperBoost: false,
    convertToT90: false,
    convertToCryo: false,
    replacedAlpha: undefined,
    convertedAlpha: '40',
    selectedCurrency: 'USD',
    potentialBatchSavings: null,
    originalProductCost: undefined,
    newProductCost: undefined,
    beerRevenuePerBbl: undefined,
    numberOfBatchesConverted: '1',
}

const currentCalculator = ref(route.params.type != undefined && route.params.type != '' ?
    route.params.type :
    'process-selector'
)

switch (currentCalculator.value) {
    case 'process-selector':
        showBrewingProcessSelector()
        break
    case 'dyna-boost':
        showDynaBoostCalculator()
        break
    case 'hyper-boost':
        showHyperBoostCalculator()
        break
}

function setInputIsDirty(value: boolean) {
    inputIsDirty.value = value
}

function setFormFilled(value: boolean) {
    formFilled.value = value
}

function showBrewingProcessSelector() {
    router.push({name: 'Efficiency Calculator', params: {type: null}})
    brewingProcessSelectorIsVisible.value = true
    hyperBoostCalculatorIsVisible.value = false
    dynaBoostCalculatorIsVisible.value = false
}

function showDynaBoostCalculator() {
    router.push({name: 'Efficiency Calculator', params: {type: 'dyna-boost'}})
    brewingProcessSelectorIsVisible.value = false
    hyperBoostCalculatorIsVisible.value = false
    dynaBoostCalculatorIsVisible.value = true
}

function showHyperBoostCalculator() {
    router.push({name: 'Efficiency Calculator', params: {type: 'hyper-boost'}})
    brewingProcessSelectorIsVisible.value = false
    hyperBoostCalculatorIsVisible.value = true
    dynaBoostCalculatorIsVisible.value = false
}

function resetInputs() {
    selectedMeasurement.value = 'Imperial'
    showBrewingProcessSelector()
    hyperBoostCalculator.value?.resetInputs()
    dynaBoostCalculator.value?.resetInputs()
    showResetModal.value = false
}
</script>

<template>
    <div class="conversion-calculator flex flex-col w-full justify-start items-center px-0">
        <!------ Title Row ------>
        <header
            class="limit-width-md w-full lg:grid mt-5 mb-10 px-3 lg:px-0"
            :class="{
        'justify-between': hyperBoostCalculatorIsVisible || dynaBoostCalculatorIsVisible,
        'justify-center': !hyperBoostCalculatorIsVisible && !dynaBoostCalculatorIsVisible
      }"
        >
            <h1 class="mb-5 text-center  pl-3 xl:pl-0">Efficiency Calculator</h1>
            <div
                class="grid controls gap-3"
                v-if="hyperBoostCalculatorIsVisible || dynaBoostCalculatorIsVisible"
            >
                <y-dropdown
                    :options="measurementOptions"
                    v-model="selectedMeasurement"
                    :allow-no-selection="false"
                />
                <y-button
                    @click="showResetModal = true"
                    background-color="secondary"
                    icon="rotate-right"
                    id="reset-btn"
                    v-if="inputIsDirty"
                    label="Reset"
                />
                <y-button
                    @click="window.print()"
                    background-color="secondary"
                    label="Print"
                    icon="file-pdf"
                    v-if="formFilled"
                />
            </div>
        </header>
        <div class="limit-width-sm pb-5 w-full items-center px-3 md:px-0" v-if="brewingProcessSelectorIsVisible">
            <p class="mb-3">
                Explore the conversion of T-90 pellets to advanced hop products and estimate replacement rates and efficiency gains.
            </p>
            <p>
                Calculations are estimates based on internal research and customer feedback. This calculator
                is intended to guide dosing rates and cost savings, individual results may vary.
            </p>
        </div>
        <div
            class="background-light-grey w-full items-center flex flex-col"
            v-if="brewingProcessSelectorIsVisible"
        >
            <brewing-process-selector
                ref="brewing-process-component"
                @showDynaBoostCalculator="showDynaBoostCalculator"
                @showHyperBoostCalculator="showHyperBoostCalculator"
            ></brewing-process-selector>

            <div class="limit-width-md w-full video">
                <iframe
                    src="https://www.youtube.com/embed/SHFH8f9giDU?si=IA48eL3vu1cT9qSA"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
        </div>
        <template v-if="hyperBoostCalculatorIsVisible">
            <hyper-boost-calculator
                ref="hyper-boost-calculator"
                :default-state="calculatorDefaultState"
                :selectedMeasurement="selectedMeasurement"
                @showBrewingProcessSelector="showBrewingProcessSelector"
                @formFilled="setFormFilled"
                @inputIsDirty="setInputIsDirty"
            ></hyper-boost-calculator>
        </template>
        <template v-if="dynaBoostCalculatorIsVisible">
            <dyna-boost-calculator
                ref="dyna-boost-calculator"
                :default-state="calculatorDefaultState"
                :selectedMeasurement="selectedMeasurement"
                @showBrewingProcessSelector="showBrewingProcessSelector"
                @formFilled="setFormFilled"
                @inputIsDirty="setInputIsDirty"
            ></dyna-boost-calculator>
        </template>
    </div>
    <reset-calculator-modal
        :open="showResetModal"
        calculator-name="Efficiency Calculator"
        @confirmed="resetInputs"
        @update:open="(openState) => (showResetModal = openState)"
    />
</template>

<style scoped lang="scss">
.conversion-calculator {
    header {
        h1 {
            font-size: 3rem;
            margin-right: 30px;
        }

        &.justify-center {
            grid-template-columns: 1fr;
            text-align: center;
        }

        &.justify-between {
            grid-template-columns: 1fr 400px;
        }

        .controls {
            grid-template-columns: 1fr 100px 100px;
            margin-top: 8px;
        }
    }

    .video {
        --video-width: 800px;
        text-align: center;
        max-width: 100%;
        width: var(--video-width);
        height: calc(var(--video-width) * (9 / 16));
        position: relative;
        margin-top: 50px;
        margin-bottom: 150px;

        iframe {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
        }
    }

    @media (min-width: 1095px) {
        padding-top: 80px;
        header {
            h1 {
                font-size: 4rem;
            }
        }
    }

    .calculator-visible {
        h1 {
            text-align: left;
        }
    }

    h2.text-white {
        color: var(--ych-white);
    }
}
</style>
