<template>
    <div class="header-wrapper flex justify-center">
        <header class="px-4 w-full sm:px-12 items-center justify-between flex relative">
            <div class="flex items-center flex-shrink-0 pr-2 relative">
                <RouterLink to="/" class="flex gap-3 no-underline items-center">
                    <img alt="YCH logo" class="logo" src="@/../assets/ych-icon.svg"/>
                    <div class="ych-nav-title pr-4 text-white uppercase">{{ appName }}</div>
                </RouterLink>
            </div>

            <div class="block">
                <div
                    v-on-click-outside="closeMainMenu"
                    class="main-menu-button"
                    @click="showMainMenu = !showMainMenu"
                >
                    <main-menu-icon :isOpen="showMainMenu"></main-menu-icon>
                </div>
                <main-menu v-if="showMainMenu"/>
            </div>
        </header>
    </div>
</template>

<script setup lang="ts">
import {ref} from 'vue'
import {RouterLink} from 'vue-router'
import {vOnClickOutside} from '@vueuse/components'
import MainMenu from './MainMenu.vue'
import MainMenuIcon from './MainMenuIcon.vue'

const showMainMenu = ref(false)

function closeMainMenu(pointerEvent: PointerEvent) {
    if (showMainMenu.value == true) {
        showMainMenu.value = false
    }
}

const appName = 'Yakima Chief Hops'
</script>

<style lang="scss" scoped>
.header-wrapper {
    background-color: var(--ych-red);
    width: 100%;
    position: relative;
    z-index: 999;
}

header {
    background-color: var(--ych-red);
    max-width: 1920px;
    height: 3.75rem;

    .ych-nav-title {

        font-weight: var(--y-font-weight-bold);
        margin-bottom: 0px;
        font-size: var(--y-font-size-2xl);
        color: var(--ych-white);
        letter-spacing: -0.00975rem;
        line-height: 2rem;
        font-style: normal;
        font-family: var(--y-font-family-condensed);
        overflow-wrap: break-word;
        -webkit-hyphens: auto;
        hyphens: auto;
    }

    img.logo {
        height: 2.5rem;
        width: auto;
        margin-top: 2px;
    }

    .main-menu-button {
        display: flex;
        align-items: center;
        grid-gap: 0.75rem;
        cursor: pointer;
    }
}
</style>
