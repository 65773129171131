<script setup lang="ts">

import {YButton, YButtonSelectable, YIcon, YInput} from "@yakimachief/ych-ts-component-library";
import {computed, Ref, ref, watch} from "vue";
import {calculateAlphaReduction, calculatePotentialBeerOutputIncrease, CalculatorDefaultState, converter, convertToGrams, cryoDisplay, dynaBoostDisplay, getProductDisplayLabel, kilogramAbbreviation, poundsAbbreviation, t90Display} from "@/composables/efficiencyConversions";
import EfficiencyResults from "@/components/EfficiencyCalculator/EfficiencyResults.vue";
import EfficiencySavings from "@/components/EfficiencyCalculator/EfficiencySavings.vue";
import BannerPicker from "@/components/EfficiencyCalculator/BannerPicker.vue";

const emit = defineEmits([
    'showBrewingProcessSelector',
    'formFilled',
    'inputIsDirty'
]);

defineExpose({
    resetInputs
})
const props = defineProps<{
    selectedMeasurement: string
    defaultState: CalculatorDefaultState
}>()


const replaceT90: Ref<boolean> = ref(props.defaultState.replaceT90)
const replaceCryo: Ref<boolean> = ref(props.defaultState.replaceCryo)

const currentUsage: Ref<string | undefined> = ref(props.defaultState.currentUsage)
const percentageToConvert: Ref<undefined | string> = ref(props.defaultState.percentageToConvert)

const convertToDynaBoost: Ref<boolean> = ref(props.defaultState.convertToDynaBoost)
const convertToT90: Ref<boolean> = ref(props.defaultState.convertToT90)
const convertToCryo: Ref<boolean> = ref(props.defaultState.convertToCryo)

const replacedAlpha: Ref<undefined | string> = ref(props.defaultState.replacedAlpha)
const convertedAlpha: Ref<undefined | string> = ref(props.defaultState.convertedAlpha)

function resetInputs() {
    replaceT90.value = props.defaultState.replaceT90
    replaceCryo.value = props.defaultState.replaceCryo
    currentUsage.value = props.defaultState.currentUsage
    percentageToConvert.value = props.defaultState.percentageToConvert
    convertToDynaBoost.value = props.defaultState.convertToDynaBoost
    convertToT90.value = props.defaultState.convertToT90
    convertToCryo.value = props.defaultState.convertToCryo
    replacedAlpha.value = props.defaultState.replacedAlpha
    convertedAlpha.value = props.defaultState.convertedAlpha
}

watch([replaceCryo, currentUsage], async (newValue, oldValue) => {
    if (replaceCryo.value && currentUsage.value) {
        convertToDynaBoost.value = true
        // convertedAlpha.value = 40;
    } else {
        convertToDynaBoost.value = false
        convertedAlpha.value = undefined;
    }
});

watch([replaceT90, replaceCryo, currentUsage, percentageToConvert, convertToDynaBoost, convertToT90, convertToCryo], async (newValue, oldValue) => {
    let hasSelectedOldProduct = replaceT90.value || replaceCryo.value;
    let hasSelectedNewProduct = convertToDynaBoost.value || convertToT90.value || convertToCryo.value;

    if (hasSelectedOldProduct && hasSelectedNewProduct && currentUsage.value && percentageToConvert.value) {
        emit('formFilled', true)
    } else {
        emit('formFilled', false)
    }

    if (hasSelectedOldProduct || hasSelectedNewProduct || currentUsage.value || percentageToConvert.value) {
        emit('inputIsDirty', true)
    } else {
        emit('inputIsDirty', false)
    }
})


const unitsAbbreviation = computed(() => {
    let unitAbbreviation = 'Unknown';
    if (props.selectedMeasurement == 'Imperial') {
        unitAbbreviation = poundsAbbreviation
    } else if (props.selectedMeasurement == 'Metric') {
        unitAbbreviation = kilogramAbbreviation
    }
    return unitAbbreviation
})

const replacedProductLabel = computed(() => {
    return getProductDisplayLabel([
        {
            'productName': t90Display,
            'shouldDisplay': replaceT90.value
        },
        {
            'productName': cryoDisplay,
            'shouldDisplay': replaceCryo.value
        }
    ])
})

const convertToProductLabel = computed(() => {
    return getProductDisplayLabel([
        {
            'productName': t90Display,
            'shouldDisplay': convertToT90.value
        },
        {
            'productName': cryoDisplay,
            'shouldDisplay': convertToCryo.value
        },
        {
            'productName': dynaBoostDisplay,
            'shouldDisplay': convertToDynaBoost.value
        }
    ])
})

const alphaDeficit = computed(() => {
    return calculateAlphaReduction(
        reductionInGrams.value,
        parseFloat(replacedAlpha.value ?? '0'),

        convertedUsage.value?.amount ?? 0,
        parseFloat(convertedAlpha.value ?? '0'),
    ).toFixed(1)

});

const currentUsageInGrams = computed(() => {
    let currentUsageNumber = parseFloat(currentUsage.value ?? '0')
    return convertToGrams(currentUsageNumber, unitsAbbreviation.value)
});

const reductionInGrams = computed(() => {
    let percentageToConvertNumber = parseFloat(percentageToConvert.value ?? '0') / 100
    return currentUsageInGrams.value * percentageToConvertNumber;
})

const reductionAmount = computed(() => {
    let currentUsageNumber = parseFloat(currentUsage.value ?? '0')
    let percentageToConvertNumber = parseFloat(percentageToConvert.value ?? '0') / 100
    return currentUsageNumber * percentageToConvertNumber;
})

const convertedUsage = computed(() => {
    return converter(replacedProductLabel.value, convertToProductLabel.value, reductionInGrams.value, props.selectedMeasurement)
})

const potentialBeerOutputIncrease = computed(() => {
    return calculatePotentialBeerOutputIncrease(replacedProductLabel.value, convertToProductLabel.value, reductionInGrams.value)
})

</script>

<template>
    <div class="background-light-grey w-full justify-between items-center">
        <div class="w-full items-center py-5">
            <div class="limit-width-md w-full grid justify-start mx-auto">
                <y-button
                    @click="$emit('showBrewingProcessSelector')"
                    size="lg"
                    icon="caret-left"
                    background-color="transparent"
                    label="Back"
                    class="back"
                />
            </div>
            <div class="question px-3 md:px-0">
                <p class="lead">For which product would you like to calculate a whirlpool substitution?</p>
                <div class="md:flex w-full justify-center md:gap-5 md:flex-row mt-5">
                    <y-button-selectable
                        size="lg"
                        :show-icon="false"
                        v-model="replaceT90"
                        @click="replaceCryo = false"
                        background-color="two-tone"
                        class="primary t90"
                        label="T-90 Pellets"
                    />
                    <y-button-selectable
                        size="lg"
                        :show-icon="false"
                        v-model="replaceCryo"
                        @click="replaceT90 = false; convertToCryo = false"
                        background-color="two-tone"
                        class="primary cryo pt-7 md:pt-0"
                        label="Cryo&reg; Pellets"
                    />
                </div>
            </div>
            <div class="question px-3 md:px-0" v-if="replacedProductLabel">
                <p class="lead">How much <span v-html="replacedProductLabel"></span> are you currently using in the whirlpool?</p>
                <div class="text-center w-full mt-5">
                    <y-input
                        v-model="currentUsage"
                        type="number"
                        class="mx-auto text-right"
                        id="currentUsage"
                        :class="unitsAbbreviation"/>
                </div>
            </div>
            <div class="question px-3 md:px-0" v-if="currentUsage">
                <p class="lead">For which product would you like to see a suggested replacement rate?</p>
                <div class="md:flex w-full justify-center md:gap-5 md:flex-row mt-5">
                    <y-button-selectable
                        size="lg"
                        :show-icon="false"
                        v-model="convertToCryo"
                        v-if="!replaceCryo"
                        @click="convertToT90 = false; convertToDynaBoost = false"
                        background-color="two-tone"
                        class="primary cryo"
                        label="Cryo&reg; Pellets"
                    />
                    <y-button-selectable
                        size="lg"
                        :show-icon="false"
                        v-model="convertToDynaBoost"
                        @click="convertToT90 = false; convertToCryo = false"
                        background-color="two-tone"
                        class="primary dynaboost pt-7 md:pt-0"
                        label="DynaBoost&trade;"
                    />
                </div>
            </div>
            <div class="question px-3 md:px-0" v-if="convertToProductLabel">
                <p class="lead">What percentage of your whirlpool addition would you like to convert?</p>
                <div class="text-center w-full mt-5">
                    <label for="percentageToConvert" class="mb-2">Percentage of <span v-html="replacedProductLabel"></span> Whirlpool to Convert</label>
                    <y-input
                        v-model="percentageToConvert"
                        type="number"
                        class="mx-auto percent text-right"
                        id="percentageToConvert"
                        :max="100"/>

                    <p class="text-sm text-gray-500 mt-4" v-if="convertToProductLabel == dynaBoostDisplay">
                        Yakima Chief Hops recommends using <span v-html="convertToProductLabel"></span> for up to 100% of your whirlpool addition.
                    </p>
                </div>
            </div>
            <div class="question px-3 md:px-0" v-if="percentageToConvert && convertToDynaBoost">
                <p class="lead">What is your current alpha acid % for each product being used?</p>
                <div class="text-center limit-width-sm mt-5 mx-auto">
                    <div class="md:grid grid-cols-2">
                        <div class="">
                            <label for="replacedAlpha" class="mb-2"><span v-html="replacedProductLabel"></span> AA%</label>
                            <y-input
                                v-model="replacedAlpha"
                                type="number"
                                class="mx-auto percent text-right"
                                id="replacedAlpha"
                            />
                        </div>
                        <div class="">
                            <label for="convertedAlpha" class="mb-2"><span v-html="convertToProductLabel"></span> AA%</label>
                            <y-input
                                v-model="convertedAlpha"
                                type="number"
                                class="mx-auto percent text-right pt-7 md:pt-0"
                                id="convertedAlpha"
                            />
                        </div>
                    </div>
                    <p class="mt-5">Unsure of your current alpha percentage? Use the <a href="/lookup" target="_blank">Lot Lookup Tool.</a></p>
                </div>
            </div>
        </div>
    </div>

    <template v-if="convertedUsage.amount !== null && convertedUsage.amount > 0">
        <efficiency-results
            :replaced-product-label="replacedProductLabel"
            :convert-to-product-label="convertToProductLabel"
            :current-usage="currentUsage"
            :reduction-amount="reductionAmount"
            :converted-usage="convertedUsage.amount"
            :converted-usage-units="convertedUsage.units"
            :current-usage-in-grams="currentUsageInGrams"
            :units-abbreviation="unitsAbbreviation"
            :potential-beer-output-increase="potentialBeerOutputIncrease"
        >
        </efficiency-results>
        <div class="warning limit-width-md" v-if="replacedAlpha && convertedAlpha && convertToProductLabel == dynaBoostDisplay">
            <y-icon icon="triangle-exclamation"/>
            <p>
                Due to the concentrated nature of <span v-html="convertToProductLabel"></span>, you’re now adding {{ alphaDeficit }}% less alpha to the whirlpool.
                You can account for this bitterness loss by adding more hops in the boil.

            </p>
            <y-icon icon="triangle-exclamation"/>
        </div>
        <efficiency-savings
            :default-state="props.defaultState"
            :replaced-product-mass="reductionAmount"
            :new-product-dose="convertedUsage.amount"
            :reduction-in-grams="reductionInGrams"
            :salePackageSize="convertedUsage.units"
            :potential-beer-output-increase="potentialBeerOutputIncrease"
            :units-abbreviation="unitsAbbreviation"
            :replaced-product-label="replacedProductLabel"
            :convert-to-product-label="convertToProductLabel"
        ></efficiency-savings>
        <banner-picker :product-name="convertToProductLabel"></banner-picker>
    </template>
</template>

<style lang="scss">
.warning {
    svg {
        margin: 0 auto;
        height: 4rem;
    }
}
</style>
<style scoped lang="scss">

.warning {
    background-color: #FBB040;
    padding: 10px;
    margin: 20px 0 30px 0;
    font-size: 1.5rem;
    font-weight: bold;
    display: grid;
    grid-template-columns: 80px 1fr 80px;
    text-align: center;
}

</style>
