<script setup lang="ts">
import { YIcon } from '@yakimachief/ych-ts-component-library'

let copyrightYear = new Date().getFullYear()
</script>
<template>
  <div class="footer-wrapper flex justify-center py-6">
    <footer class="footer px-4 w-full sm:px-12 items-center justify-between flex relative">
      <div class="footer__content flex print:hidden">
        <a href="https://www.yakimachief.com/" class="flex gap-3 no-underline items-center">
          <img
            alt="YCH logo"
            class="footer__content__logo print:hidden"
            src="@/../assets/ych-logo_h_white.svg"
          />
        </a>
        <ul class="footer__content__links inline-flex">
          <!--<li><a href="https://portal.yakimachief.com">Portal</a></li>-->
          <li><a href="https://yakimachief.com/?ref=tools">Shop Hops</a></li>
          <li>
            <a href="https://www.yakimachief.com/commercial/about-us/our-company">About Us</a>
          </li>
          <li>
            <a href="https://www.yakimachief.com/commercial/resources/beer-recipes">Recipes</a>
          </li>
          <li><a href="mailto:hops@yakimachief.com" target="_blank">Support</a></li>
        </ul>
        <ul class="footer__content__social inline-flex">
          <li>
            <a href="https://www.facebook.com/YakimaChief/">
              <y-icon icon="facebook" />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/yakimachief/"><y-icon icon="instagram" /></a>
          </li>
          <li>
            <a href="https://twitter.com/YakimaChief"><y-icon icon="twitter" /></a>
          </li>

          <li>
            <a href="https://www.youtube.com/channel/UCV9fxs7MhMinq6HzVk3u4Pg"
              ><y-icon icon="youtube"
            /></a>
          </li>
          <li>
            <a href="https://soundcloud.com/user-570732709-200725265"
              ><y-icon icon="soundcloud"
            /></a>
          </li>
        </ul>
      </div>
      <div class="footer__legal print:hidden">
        <small>{{ copyrightYear }} &copy; Yakima Chief Hops Inc. All Rights Reserved.</small>
        <small
          ><a href="https://www.yakimachief.com/privacy-policy-cookie-restriction-mode"
            >Privacy Statement</a
          ></small
        >
        <small
          ><a href="https://www.yakimachief.com/terms-conditions">Terms &amp; Conditions</a></small
        >
      </div>
      <small class="hidden print:block"
        >{{ copyrightYear }} &copy; Yakima Chief Hops Inc. All Rights Reserved.</small
      >
    </footer>
  </div>
</template>
<style scoped lang="scss">
.footer-wrapper {
  background-color: var(--ych-blue);
  width: 100%;
  height: auto;
  .footer {
    background-color: var(--ych-blue);
    max-width: 1920px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    &__content {
      width: 100%;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 1rem;
      padding-bottom: 1rem;

      &__logo {
        height: 2.5rem;
        width: auto;
      }

      &__links {
        list-style-type: none;
        padding: 0;
        margin: 0;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        a {
          padding: 0.5rem 0.5rem;
          height: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--ych-white);
          border-radius: var(--y-radius-md);

          &:hover {
            background: var(--ych-white);
            color: var(--ych-blue);
            text-decoration: none;
          }
        }
      }

      &__social {
        font-size: 1.5rem;
        list-style-type: none;
        padding: 0;
        margin: 0;
        align-items: center;
        justify-content: center;
        gap: 0.25rem;

        a {
          width: 2.5rem;
          height: 2.5rem;
          padding: 0.25rem 0.25rem;
          color: var(--ych-white);
          text-decoration: none;
          border-radius: var(--y-radius-md);
          display: flex;
          justify-content: center;
          align-items: center;

          &:hover {
            background: var(--ych-white);
            color: var(--ych-blue);
          }
        }
      }
    }

    &__legal {
      small {
        color: var(--ych-grey-400);
        font-weight: var(--y-font-weight-md);
        a {
          text-decoration: none;
          font-weight: var(--y-font-weight-md);

          &:hover {
            text-decoration: underline;
            color: var(--ych-grey-200);
          }
        }

        &::after {
          content: '\2022';
          padding-left: 1rem;
          padding-right: 1rem;
        }
        &:last-child::after {
          content: none;
          padding: 0;
        }
      }
    }
  }
}

@media (min-width: 1024px) {
  .footer-wrapper {
    height: 9.75rem;
    .footer {
      &__content {
        flex-direction: row;
        justify-content: space-between;
        gap: 0.25rem;

        &__links {
          gap: 1rem;
        }
      }
    }
  }
}
</style>
