<script setup lang="ts">
</script>

<template>
    <div class="w-full product-highlight d-print-none">
        <div class="md:grid limit-width-md mx-auto">
            <img src="/img/hyperboost-logo.png" alt="HyperBoost™ Product Logo" class="pb-5 md:pb-0 px-5 md:px-0 m-auto"/>
            <div>
                <h2 class="prominent-heading">HyperBoost™ Product Sheets</h2>
                <p class="mb-7 subtitle">Download our HyperBoost™ Product Sheets</p>
                <ul class="product-sheets mb-7 list-style-none">
                    <li class="xl:inline">
                        <a
                            href="https://www.yakimachief.com/media/wysiwyg/documents/HyperBoost_-_Product_Data_Sheet_-_2025.pdf"
                        >
                            HyperBoost™ Product Data Sheet
                        </a>
                    </li>
                    <li class="xl:inline">
                        <a
                            href="https://www.yakimachief.com/media/wysiwyg/documents/HyperBoost_-_Product_Overview_Sheet_2025.pdf"
                        >
                            HyperBoost™ Overview Sheet
                        </a>
                    </li>
                    <li class="xl:inline">
                        <a
                            href="https://www.yakimachief.com/media/wysiwyg/documents/HyperBoost_-_Technical_One_Sheet_2025.pdf"
                        >
                            HyperBoost™ Technical One Sheet
                        </a>
                    </li>
                </ul>
                <div class="flex flex-row gap-5">
                    <a href="https://www.yakimachief.com/commercial/contact" class="btn secondary">
                        Questions about HyperBoost™? Contact us!
                    </a>
                    <a href="https://www.yakimachief.com/commercial-hyperboost" class="btn primary">
                        Ready to buy HyperBoost™? Shop now!
                    </a>
                </div>
            </div>
        </div>
        <div class="hyperboost banner">
            <div class="video">
                <iframe
                    src="https://www.youtube.com/embed/P-3ZOzEyl7E?si=nGSiTQ1OTn-25IuW"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.btn {
    padding: var(--y-p-xs) var(--y-p-sm);
    border-radius: var(--y-radius-sm);
    min-width: 2rem;
    min-height: 2rem;
    gap: var(--y-gap-md);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border: 0;
    font-family: var(--y-font-family-sans-serif);
    font-weight: var(--y-font-weight-medium);
    font-size: var(--y-font-size-md);
    cursor: pointer;

    &.primary {
        color: var(--ych-white);
        background-color: var(--ych-red);
    }

    &.secondary {
        color: var(--ych-white);
        background-color: var(--ych-blue);
    }
}
</style>
