<script setup lang="ts">

import {Ref, ref} from "vue";
import FreshHopVariety from "@/components/fresh-hops/FreshHopVariety.vue";
import axios from "axios";

const errors: Ref<Record<string, string[]> | null> = ref(null)
const breweryName = ref('')
const contactName = ref('')
const contactPhone = ref('')
const contactEmail = ref('')
const termsAccepted = ref(false)
const submittingData = ref(false)
const saveComplete = ref(false)
const freshHopVariety = ref(null)
const selectedVarieties: Ref<SelectedVariety[]> = ref([])

const availableVarieties = ref([]);
const contentLibrary: Ref<Record<string, string>> = ref({});

const header = ref('Fresh Hop: FARM PICKUP Order Form 2025')

fetchAvailableVarieties();
fetchContent();

function resetForm() {
    breweryName.value = '';
    contactName.value = '';
    contactPhone.value = '';
    contactEmail.value = '';
    termsAccepted.value = false;
    selectedVarieties.value = [];
    fetchAvailableVarieties();
}

function fetchAvailableVarieties() {
    availableVarieties.value = []
    axios.get('api/fresh-hop-varieties')
    .then((response) => {
        availableVarieties.value = response.data.data;
    }).catch((error) => {
        console.error('Error fetching available varieties:', error);
    });
}

function fetchContent() {
    axios.get('api/fresh-hop-content')
    .then((response) => {
        contentLibrary.value = response.data.data;
        header.value = contentLibrary.value['fresh_hop_header'];
    }).catch((error) => {
        console.error('Error fetching content:', error);
    });
}

function updateVarietyList(selectedVariety: SelectedVariety) {

    let index = selectedVarieties.value.findIndex(variety => variety.variety === selectedVariety.variety);
    if (index >= 0) {
        if (selectedVariety.selected) {
            selectedVarieties.value.splice(index, 1, selectedVariety);
        } else {
            // If the variety is deselected, remove it from the list
            selectedVarieties.value.splice(index, 1);
        }
    } else if (selectedVariety.selected) {
        selectedVarieties.value.push(selectedVariety)
    }
}

function submitForm() {
    let data = {
        breweryName: breweryName.value,
        contactName: contactName.value,
        contactPhone: contactPhone.value,
        contactEmail: contactEmail.value,
        selectedVarieties: selectedVarieties.value,
        termsAndCondition: termsAccepted.value,
    };

    if (!submittingData.value) {
        submittingData.value = true;
        axios.post('/api/fresh-hop-order', data)
        .then((response) => {
            if (response.data.message === 'success') {
                resetForm();
                errors.value = null;
                saveComplete.value = true;
            } else {
                errors.value = response.data.errors ?? null;
            }
        }).catch((error) => {
            errors.value = error.response?.data ?? null;
        }).finally(() => {
            submittingData.value = false;
        });
    } else {
        console.log('Already submitting data');
    }
}
</script>

<template>
    <div class="flex items-center justify-center min-h-screen">
        <div class="bg-white p-8 rounded shadow-md w-full max-w-screen-lg">
            <div class="flex justify-between">
                <div>
                    <h1>{{ header }}</h1>
                    <p class="subtitle">{{ contentLibrary['fresh_hop_sub-header'] }}</p>
                </div>
                <div>
                    <button class="btn btn-secondary btn-sm" @click="resetForm">Reset Form</button>
                </div>
            </div>
            <div v-if=saveComplete class="success">
                <p>Your order has been submitted. Thank you!</p>
            </div>
            <div class="form-input">
                <p v-if="errors && errors['breweryName']" class="error"> {{ errors['breweryName'].join(',') }}</p>
                <label for="brewery-name">Brewery Name</label>
                <input v-model="breweryName" type="text" id="brewery-name" name="brewery-name" placeholder="Enter your brewery name" required/>
            </div>

            <div class="form-input">

                <p v-if="errors && errors['contactName']" class="error"> {{ errors['contactName'].join(',') }}</p>
                <label for="contact-name">Contact Name</label>
                <input v-model="contactName" type="text" id="contact-name" name="contact-name" placeholder="Enter your contact name" required/>
            </div>

            <div class="form-input">
                <p v-if="errors && errors['contactPhone']" class="error"> {{ errors['contactPhone'].join(',') }}</p>
                <label for="contact-phone">Contact Phone</label>
                <input v-model="contactPhone" type="text" id="contact-phone" name="contact-phone" placeholder="Enter your contact phone" required/>
            </div>

            <div class="form-input">
                <p v-if="errors && errors['contactEmail']" class="error"> {{ errors['contactEmail'].join(',') }}</p>
                <label for="contact-email">Contact Email</label>
                <input v-model="contactEmail" type="text" id="contact-email" name="contact-email" placeholder="Enter your contact email" required/>
            </div>
            <div class="form-content yearly-updates">
                <div v-html="contentLibrary['fresh_hop_yearly-updates']" class="pt-3 pb-5"></div>
            </div>
            <div class="form-content">
                <h2>All Varieties @ $ 4.00/lb</h2>
                <p v-if="errors && errors['selectedVarieties']" class="error"> {{ errors['selectedVarieties'].join(',') }}</p>
                <div v-for="(variety, index) in availableVarieties" :key="variety.variety_id">
                    <fresh-hop-variety
                        ref="freshHopVariety"
                        @update="updateVarietyList"
                        :variety="variety">
                    </fresh-hop-variety>
                    <p v-if="errors && errors['selectedVarieties.'+index+'.amount']" class="error"> {{ errors['selectedVarieties.' + index + '.amount'].join(',') }}</p>
                    <p v-if="errors && errors['selectedVarieties.'+index+'.grower']" class="error"> {{ errors['selectedVarieties.' + index + '.grower'].join(',') }}</p>
                </div>

                <h2>Terms and Conditions:</h2>
                <div v-html="contentLibrary['fresh_hop_terms']" class="py-3"></div>

                <div class="form-input">
                    <p v-if="errors && errors['termsAndCondition']" class="error"> {{ errors['termsAndCondition'].join(',') }}</p>
                    <h3 class="mb-2">Customer Acknowledgement - Terms and Conditions</h3>
                    <label for="terms-accepted">
                        <input v-model="termsAccepted" type="checkbox" class="d-inline w-auto" id="terms-accepted" required/>
                        By checking the box below, customer agrees they have read the information above and they agree to the terms and conditions.
                        <p v-if="errors && errors['termsAccepted']" class="error"> {{ errors['termsAccepted'].join(',') }}</p>
                    </label>
                </div>

                <button class="btn btn-primary" :class="{
                    disabled:submittingData
                }" type="submit" @click="submitForm">
                    Submit Order
                </button>
            </div>
        </div>
    </div>

</template>

<style lang="scss">

.yearly-updates {
    h1, h2, h3, h4, p {
        margin-bottom: 20px;
    }
}
</style>

<style lang="scss" scoped>
.error {
    color: red;
}

.success {
    background-color: rgb(0, 128, 0, .7);
    border: 1px solid rgb(0, 128, 0);
    padding: 15px;
    margin-bottom: 20px;

    p {
        color: white;
    }
}

.subtitle {

    margin-bottom: 2rem;
}

.form-input {
    margin-bottom: 1rem;

    input.w-auto {
        width: auto !important;
    }

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    input {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
    }

    .error {
        color: red;
        font-size: 0.875rem;
        margin-top: 0.25rem;
    }
}

.form-content {
    margin-bottom: 2rem;

    h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
    }
}

button.btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    border: none;
    float: right;
    border-radius: 4px;
    cursor: pointer;

    &.btn-primary {
        background-color: var(--ych-red);
    }

    &.btn-secondary {
        background-color: var(--ych-blue);
    }

    &.btn-sm {
        padding: 0.25rem 1rem;
    }

    &.disabled {
        background-color: grey;
    }

    &:hover {
        background-color: var(--ych-red);
    }
}
</style>
