<template>
  <div class="calculators flex flex-col w-full gap-6 py-12 items-center flex-auto px-3 md:px-0">
    <h1 class="prominent-heading text-center">Yakima Chief Hops - Brewing Calculators</h1>
    <div class="calculators__buttons limit-width-sm justify-center items-center">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h1>Efficiency Calculator</h1>
          <p>
            This calculator helps estimate the potential efficiency gains offered by different aroma
            products, such as DynaBoost™, HyperBoost™, or Cryo Hops®.
          </p>
        </div>
        <div>
          <y-button
            @enter="navigateToEfficiency"
            @click="navigateToEfficiency"
            width="auto"
            icon="caret-right"
            icon-position="right"
            background-color="primary"
            class="cryo"
            label="Efficiency Calculator"
          />
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h1>IBU Estimator</h1>
          <p>
            This calculator estimates bittering units absorbed in the wort during kettle additions
            of T-90, Cryo Hops®, extract, or leaf hops.
          </p>
        </div>
        <div>
          <y-button
            @enter="navigateToIBU"
            @click="navigateToIBU"
            width="auto"
            icon="caret-right"
            icon-position="right"
            background-color="primary"
            class="ibu"
            label="IBU Estimator"
          />
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h1>Extract Calculator</h1>
          <p>
            This conversion calculator estimates the replacement rate of hop oil extract from T-90
            pellets, leaf hops, or other products.
          </p>
        </div>
        <div>
          <y-button
            @enter="navigateToExtract"
            @click="navigateToExtract"
            width="auto"
            icon="caret-right"
            icon-position="right"
            background-color="primary"
            class="extract"
            label="Extract Conversion"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router'
import { YButton } from '@yakimachief/ych-ts-component-library'

const router = useRouter()

const navigateToExtract = () => {
  router.push({ path: 'calculators/extract' })
}
const navigateToIBU = () => {
  router.push({ path: 'calculators/ibu' })
}
const navigateToCryo = () => {
  router.push({ path: 'calculators/cryo' })
}
const navigateToEfficiency = () => {
  router.push({ path: 'calculators/efficiency' })
}
</script>

<style lang="scss">
.calculators {
  border-top: 1px solid var(--ych-grey-200);
  background-color: var(--ych-grey-100);
  padding: 0 1rem;

  p {
    hyphens: none;
  }

  h3 {
    text-transform: none;
  }

  .prominent-heading {
    font-size: 2.25rem;
    margin-top: 1rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      margin-top: 1.5rem;
    }
  }

  h1 {
    font-size: 1.5rem;

    @media (max-width: 768px) {
      font-size: 1.15rem;
    }
  }

  &__buttons {
    > .grid {
      margin-bottom: 25px;
      gap: 50px;
      //grid-template-columns: 1fr 200px;

      @media (max-width: 768px) {
        grid-template-columns: 1fr;
        gap: 20px;
      }
    }

    .cryo.y-button {
      .y-button__button {
        background: var(--ych-cryo);
        font-weight: bold;
      }
    }

    .ibu.y-button {
      .y-button__button {
        background: var(--ych-red);
        font-weight: bold;
      }
    }

    .extract.y-button {
      .y-button__button {
        background: var(--ych-extract);
        font-weight: bold;
      }
    }
  }
}
</style>
