<script setup lang="ts">
</script>

<template>
    <div class="w-full product-highlight d-print-none">
        <div class="grid limit-width-md w-full grid mx-auto">
            <img src="/img/ych-cryo_blue.svg" alt="Cryo® Product Logo"/>
            <div>
                <h2 class="prominent-heading">Cryo® Product Sheets</h2>
                <p class="mb-7 subtitle">Download our Cryo® Product Sheets</p>
                <ul class="product-sheets mb-7 list-style-none">
                    <li class="xl:inline">
                        <a
                            href="https://www.yakimachief.com/media/documents/Cryo_Hops_Product_Data_Sheet.pdf"
                        >
                            Cryo<sup>®</sup> Data Sheet
                        </a>
                    </li>
                    <li class="xl:inline">
                        <a
                            href="https://www.yakimachief.com/media/documents/Cryo_Hops_Product_Overview_Sheet_1.pdf"
                        >
                            Cryo<sup>®</sup> Overview Sheet
                        </a>
                    </li>
                    <li class="xl:inline">
                        <a
                            href="https://www.yakimachief.com/media/documents/Cryo_Hops_FAQs.pdf"
                        >
                            Cryo<sup>®</sup> Hops vs Others FAQ
                        </a>
                    </li>
                </ul>
                <div class="flex flex-row gap-5">
                    <a href="https://www.yakimachief.com/commercial/contact" class="btn secondary">
                        Questions about Cryo®? Contact us!
                    </a>
                    <a href="https://www.yakimachief.com/commercial-cryo-hops" class="btn primary">
                        Ready to buy Cryo®? Shop now!
                    </a>
                </div>
            </div>
        </div>
        <div class="cryo banner">
            <div class="video">
                <iframe
                    src="https://www.youtube.com/embed/D-24IgJmeR8?si=SpyGM9SFOpbmP72o"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerpolicy="strict-origin-when-cross-origin"
                    allowfullscreen
                ></iframe>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.btn {
    padding: var(--y-p-xs) var(--y-p-sm);
    border-radius: var(--y-radius-sm);
    min-width: 2rem;
    min-height: 2rem;
    gap: var(--y-gap-md);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: 0;
    font-family: var(--y-font-family-sans-serif);
    font-weight: var(--y-font-weight-medium);
    font-size: var(--y-font-size-md);
    line-height: 1;
    cursor: pointer;

    &.primary {
        color: var(--ych-white);
        background-color: var(--ych-red);
    }

    &.secondary {
        color: var(--ych-white);
        background-color: var(--ych-blue);
    }
}
</style>
