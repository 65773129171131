<template>
  <CalculatorButtons />
</template>
<script setup lang="ts">
import CalculatorButtons from '@/components/CalculatorButtons.vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
switch (route.hash) {
  case '#calc-conversion':
  case '#calc-conversion-tab':
    router.push({ name: 'Extract Conversion Calculator' })
    break
  case '#calc-ibu':
  case '#calc-ibu-tab':
    router.push({ name: 'IBU Calculator' })
    break
  case '#calc-cryo':
  case '#calc-cryo-tab':
      router.push({ name: 'Efficiency Calculator' })
    break
  default:
    router.push({ name: 'YCH Brewing Tools' })
}
</script>
