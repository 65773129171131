<script setup lang="ts">

import {YIcon} from "@yakimachief/ych-ts-component-library";
import {cryoDisplay, getProductClass, t90Display} from "@/composables/efficiencyConversions";
import {computed} from "vue";

const props = defineProps<{
    replacedProductLabel: string,
    convertToProductLabel: string,
    reductionAmount: number | undefined,
    currentUsage: string | undefined,
    convertedUsage: number | string,
    unitsAbbreviation: string,
    convertedUsageUnits: string,
    currentUsageInGrams: number,
    potentialBeerOutputIncrease: number,
}>()

const absorptionRate = computed(() => {
    let absorptionRate = null;
    switch (props.replacedProductLabel) {
        case t90Display:
            absorptionRate = 10;
            break;
        case cryoDisplay:
            absorptionRate = 5;
            break;
        default:
            absorptionRate = 10;
            break;
    }

    return absorptionRate
})
</script>

<template>
    <div class="results w-full justify-between items-center">
        <div class="w-full justify-between items-center py-5">

            <div class="limit-width-md mx-auto mt-10">
                <h2 class="prominent-heading text-center pb-3">Conversion Results</h2>
                <p class=" px-3 xl:px-0">
                    Results are based on internal research and data collection, brewer experience and feedback, and an estimated pellet absorption rate of {{ absorptionRate }}%.
                    The suggested equivalent dosage rate is intended as a starting point, as individual results will vary based on process and equipment.
                </p>

                <div class="grid grid-cols-2 gap-5 mt-10 px-3 xl:px-0">
                    <div class="current">
                        <p class="lead text-left uppercase">Current <span v-html="replacedProductLabel"></span> dry hop Dose</p>
                    </div>
                    <div class="converted">
                        <p class="lead text-left uppercase"><span v-html="convertToProductLabel"></span> dry hop Equivalent Dose
                            <span
                                v-tooltip="{
                                          html: true,
                                          content: `
                                    T-90 to Cryo = 2:1<br/>
                                    T-90 to DynaBoost = 10:1<br/>
                                    T90 to HyperBoost = 100:1<br/>
                                    Cryo to DynaBoost = 5:1<br/>
                                    Cryo to HyperBoost = 50:1
                                  `
                            }"
                            >
                <y-icon icon="circle-info-solid"/>
              </span>
                        </p>
                    </div>
                </div>
                <div class="dosing-conversion grid">
                    <div class="original">
                        {{ reductionAmount }} {{ unitsAbbreviation }}
                    </div>
                    <div class="transition" :class="getProductClass(convertToProductLabel)">
                        <div class="arrow">
                        </div>
                    </div>
                    <div class="converted" :class="getProductClass(convertToProductLabel)">
                        {{ convertedUsage }} {{ convertedUsageUnits }}
                    </div>
                </div>
                <div class="potential-increase mt-5" v-if="props.convertToProductLabel !== t90Display">
                    <p class="lead uppercase">Potential Yield Increase</p>
                    <div class="result">{{ potentialBeerOutputIncrease }}L</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.current, .converted {

    p.lead {
        font-size: 1.6rem;
        font-weight: bold;
        text-align: left;
        margin-bottom: 20px;
    }
}

.potential-increase,
.dosing-conversion {
    font-weight: bold;
    text-align: center;
    font-size: 5rem;
}

.dosing-conversion {
    --result-bar-height: 130px;

    grid-template-columns: 1fr 100px 1fr;

    .original, .converted {
        padding: calc(var(--result-bar-height) * .15) 0;

        &.cryo {
            background-color: var(--ych-cryo);
        }

        &.hyperboost {
            background-color: var(--ych-hyperboost);
        }

        &.dynaboost {
            background-color: var(--ych-dynaboost);
        }
    }

    .original {
        background-color: var(--ych-grey-200);
        color: white;
    }

    .converted {
        background-color: var(--ych-blue);
        color: white;
    }

    .transition {
        background-color: var(--ych-blue);

        &.cryo {
            background-color: var(--ych-cryo);
        }

        &.hyperboost {
            background-color: var(--ych-hyperboost);
        }

        &.dynaboost {
            background-color: var(--ych-dynaboost);
        }

        .arrow {
            width: 0;
            height: 0;
            border-top: calc(var(--result-bar-height) * .6) solid transparent;
            border-bottom: calc(var(--result-bar-height) * .6) solid transparent;
            border-left: calc(var(--result-bar-height) * .6) solid var(--ych-grey-200);

            &.cryo {
                border-left: calc(var(--result-bar-height) * .6) solid var(--ych-cryo);
            }

            &.hyperboost {
                border-left: calc(var(--result-bar-height) * .6) solid var(--ych-hyperboost);
            }

            &.dynaboost {
                border-left: calc(var(--result-bar-height) * .6) solid var(--ych-dynaboost);
            }
        }
    }
}

.potential-increase {
    .result {
        margin: 0 auto;
        width: 350px;
        color: white;
        background-color: var(--ych-blue);
    }
}

@media (max-width: 960px) {
    .dosing-conversion {
        --result-bar-height: 70px;
        .transition {
            .arrow {
                border-top: calc(var(--result-bar-height) * .6) solid transparent;
                border-bottom: calc(var(--result-bar-height) * .6) solid transparent;
                border-left: calc(var(--result-bar-height) * .6) solid var(--ych-grey-200);

                &.cryo {
                    border-left: calc(var(--result-bar-height) * .6) solid var(--ych-cryo);
                }

                &.hyperboost {
                    border-left: calc(var(--result-bar-height) * .6) solid var(--ych-hyperboost);
                }

                &.dynaboost {
                    border-left: calc(var(--result-bar-height) * .6) solid var(--ych-dynaboost);
                }
            }
        }
    }
    .potential-increase, .dosing-conversion {
        font-size: 3rem;
    }
    .current, .converted, .potential-increase {
        p.lead {
            font-size: 1.3rem;
            margin-bottom: 10px;
        }
    }
}


</style>
