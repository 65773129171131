<script setup lang="ts">
import {computed, Ref, ref, watch} from 'vue'
import {calculateSavings, CalculatorDefaultState, cryoDisplay, currencyOptions, currencySymbols, dynaBoostDisplay, getProductClass, getProductPackageDetails, getProductPricing, hyperBoostDisplay} from '@/composables/efficiencyConversions'
import {YDropdown, YIcon, YInput} from '@yakimachief/ych-ts-component-library'
import ProductPackageDetails from "@/types/ProductPackageDetails";

const props = defineProps<{
    replacedProductMass: number
    defaultState: CalculatorDefaultState
    newProductDose: number
    reductionInGrams: number
    potentialBeerOutputIncrease: number

    replacedProductLabel: string
    convertToProductLabel: string
    unitsAbbreviation: string
    salePackageSize: string
}>()

const selectedCurrency: Ref<string> = ref(props.defaultState.selectedCurrency)
const potentialBatchSavings: Ref<number | null> = ref(props.defaultState.potentialBatchSavings)

const originalProductCost: Ref<undefined | string> = ref(props.defaultState.originalProductCost)
const newProductCost: Ref<undefined | string> = ref(props.defaultState.newProductCost)
const beerRevenuePerBbl: Ref<undefined | string> = ref(props.defaultState.beerRevenuePerBbl)
const numberOfBatchesConverted: Ref<undefined | string> = ref(props.defaultState.numberOfBatchesConverted)

const volumeUnit = computed(() => {
    return props.unitsAbbreviation === 'kg' ? 'hl' : 'bbl'
})

const selectedCurrencySymbol = computed(() => {
    return currencySymbols[selectedCurrency.value]
})
const potentialYearlySavings = computed(() => {
    if (potentialBatchSavings.value && numberOfBatchesConverted.value) {
        return potentialBatchSavings.value * parseFloat(numberOfBatchesConverted.value)
    }
    return null
})

const convertedProductPricing: Ref<ProductPackageDetails | null> = computed(() => {
    return getProductPackageDetails(props.convertToProductLabel, props.unitsAbbreviation)
});

function runCalculation() {
    if (
        (props.convertToProductLabel === dynaBoostDisplay || props.convertToProductLabel === hyperBoostDisplay || props.convertToProductLabel === cryoDisplay) &&
        originalProductCost.value && newProductCost.value && beerRevenuePerBbl.value && numberOfBatchesConverted.value) {

        let newProductCostByG = 0;
        switch (props.convertToProductLabel) {
            case dynaBoostDisplay:
                newProductCostByG = parseFloat(newProductCost.value) / 1000;
                break;
            case hyperBoostDisplay:
                newProductCostByG = parseFloat(newProductCost.value) / 100;
                break;
        }
        potentialBatchSavings.value = calculateSavings(
            props.replacedProductMass,
            parseFloat(originalProductCost.value),
            props.newProductDose,
            newProductCostByG,
            parseFloat(beerRevenuePerBbl.value),
            props.potentialBeerOutputIncrease
        )
    } else {
        potentialBatchSavings.value = null
    }
}

runCalculation();

watch([originalProductCost, newProductCost, beerRevenuePerBbl, numberOfBatchesConverted], async (newValue, oldValue) => {
    runCalculation()
})

</script>

<template>
    <div class="results w-full justify-between items-center savings">
        <div class="w-full justify-between items-center pb-10 input">
            <div class="limit-width-md mx-auto mb-5 px-3 xl:px-0">
                <div>
                    <h2 class="mb-4">
                        Enter your beer revenue per batch and cost of hops to view potential savings!
                    </h2>
                    <div class="md:grid cost-form gap-5">
                        <div>
                            <div class="mb-3">
                                <y-dropdown
                                    label="Currency"
                                    :options="currencyOptions"
                                    v-model="selectedCurrency"
                                    class="grid w-full col-span-1"
                                    :allow-no-selection="false"
                                />
                            </div>
                        </div>
                        <div>
                            <div class="mb-3">
                                <label for="originalProductCost" class="mb-2">
                                    <span v-html="replacedProductLabel"></span> Cost / {{ unitsAbbreviation }}
                                    <span
                                        v-tooltip="{
                                          html: true,
                                          content: replacedProductLabel+` price varies based on variety.
                                                Spot pricing is typically `+getProductPricing(replacedProductLabel,  props.unitsAbbreviation)+`.`
                                        }"
                                    >
                                    <y-icon icon="circle-info-solid"/>
                                  </span>
                                </label>
                                <y-input
                                    v-model="originalProductCost"
                                    type="number"
                                    class="text-right"
                                    :class="selectedCurrency"
                                    id="originalProductCost"
                                />
                            </div>
                            <div class="mb-3">
                                <label for="beerRevenuePerBbl" class="mb-2">
                                    Beer Revenue / {{ volumeUnit }}
                                </label>
                                <y-input
                                    v-model="beerRevenuePerBbl"
                                    type="number"
                                    class="text-right"
                                    :class="selectedCurrency"
                                    id="beerRevenuePerBbl"
                                />
                            </div>
                        </div>
                        <div>
                            <div class="mb-3">
                                <label for="newProductCost" class="mb-2">
                                    <span v-html="convertToProductLabel"></span>
                                    Cost / {{ convertedProductPricing.packageSize }}
                                    <span
                                        v-tooltip="{
                                          html: true,
                                          content: convertToProductLabel+` price varies based on variety.
                                                    Spot pricing is typically `+getProductPricing(convertToProductLabel, props.unitsAbbreviation)+`.`
                                            }"
                                    >
                                    <y-icon icon="circle-info-solid"/>
                                  </span>

                                </label>
                                <y-input
                                    v-model="newProductCost"
                                    type="number"
                                    class="text-right"
                                    :class="selectedCurrency"
                                    id="newProductCost"
                                />
                            </div>
                            <div class="mb-3">
                                <label for="numberOfBatchesConverted" class="mb-2">
                                    Batches Converted To <span v-html="convertToProductLabel"></span> / Year
                                </label>
                                <y-input
                                    v-model="numberOfBatchesConverted"
                                    type="number"
                                    class="text-right number-sign"
                                    id="numberOfBatchesConverted"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="limit-width-md mx-auto md:grid grid-cols-2 gap-5 output" v-if="potentialBatchSavings && potentialYearlySavings">
                <div>
                    <p class="lead uppercase mb-2">Potential Revenue Increase / Batch</p>
                    <div class="color-bar" :class="getProductClass(convertToProductLabel)">
                        {{ selectedCurrencySymbol }}{{ Math.round(potentialBatchSavings) }}
                    </div>
                </div>
                <div>
                    <p class="lead uppercase mb-2">Potential Revenue Increase / Year</p>
                    <div class="color-bar" :class="getProductClass(convertToProductLabel)">
                        {{ selectedCurrencySymbol }}{{ Math.round(potentialYearlySavings) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.conversion-calculator {
    label.y-dropdown__label {
        font-weight: bold;
        font-size: 1.2rem;
        line-height: 2rem;
    }

    .y-dropdown__select {
        height: 2.9rem;
        border: 3px solid var(--ych-blue);
    }
}
</style>
<style scoped lang="scss">
.conversion-calculator {
    h2 {
        font-size: 2.3rem;

    }

    .lead {
        font-size: 1.5rem;
    }

    .cost-form {
        grid-template-columns: 250px 1fr 1fr;
    }

    .y-dropdown__label,
    label {
        line-height: 2rem;
        font-weight: bold;
        font-size: 1.2rem;
    }

    .color-bar {
        background-color: var(--ych-red);
        color: var(--ych-white);
        font-size: 5rem;
        padding: 20px 0px;
        font-weight: bold;
        text-align: center;

        &.cryo {
            background-color: var(--ych-cryo);
        }

        &.dynaboost {
            background-color: var(--ych-dynaboost);
        }

        &.hyperboost {
            background-color: var(--ych-hyperboost);
        }
    }
}
</style>
