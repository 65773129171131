<script setup lang="ts">
import {Ref, ref, watch} from "vue";

const emit = defineEmits(['update'])

const props = defineProps([
    'variety'
])

const varietySelected: Ref<boolean> = ref(false)
const selectedGrowerLocation = ref(null)
const selectedAmount = ref(null)

function resetForm() {
    varietySelected.value = false
    selectedGrowerLocation.value = null
    selectedAmount.value = null
    console.log("resetting form")
}

// watch works directly on a ref
watch([varietySelected, selectedGrowerLocation, selectedAmount], async (newQuestion, oldQuestion) => {
    let varietySelection: SelectedVariety = {
        variety: props.variety.variety_id,
        selected: varietySelected.value,
        grower_location_id: selectedGrowerLocation.value,
        amount: selectedAmount.value,
    }

    emit('update', varietySelection)
})


</script>

<template>
    <div class="variety">
        <h3>
            <input type="checkbox" :id="variety.variety_id" value="true" v-model="varietySelected" class="mr-2"/>
            <label :for="variety.variety_id">
                {{ variety.variety_name }}
            </label>
        </h3>
        <div class="form-input">
            Estimated Harvest Window: <span class="font-bold">{{ variety.fresh_hop_window }}</span>
        </div>
        <div v-if="varietySelected" class="variety-details">
            <div class="form-input">
                <label :for="'selectFarm'+variety.variety_id" class="font-bold">Select Farm:</label>
                Farm Pickup:
                <select class="font-bold" v-model="selectedGrowerLocation" id="'selectFarm'+variety.variety_id">
                    <option v-for="location in variety.fresh_hop_grower_locations" :key="location.id" :value="location.id">
                        {{ location?.grower?.name }} - {{ location.name }} - {{ location?.grower?.state }}
                    </option>
                </select>
            </div>
            <div class="form-input">
                <label :for="'amount'+variety.variety_id" class="font-bold">{{ variety.variety_name }} Total LBS</label>
                <input id="'amount'+variety.variety_id" type="number" v-model="selectedAmount" min="50" placeholder="50" class="font-bold"/>
                <p class="text-right">All orders must be in 50lb increments</p>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.variety {
    background-color: #ededed;
    padding: 1rem;
    border-bottom: 1px solid #999;
    margin-bottom: 20px;
}

.form-input {
    margin-bottom: 1rem;

    label {
        display: block;
        font-weight: bold;
        margin-bottom: 0.5rem;
    }

    input, select {
        width: 100%;
        padding: 0.5rem;
        border: 1px solid #ccc;
        border-radius: 4px;
        font-size: 1rem;
    }

    .error {
        color: red;
        font-size: 0.875rem;
        margin-top: 0.25rem;
    }
}

.form-content {
    margin-bottom: 2rem;

    h2 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 1rem;
    }
}

.hidden-section {
    display: none;

    &.open {
        display: block;
    }
}

button.btn {
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &.btn-primary {
        background-color: #007bff;
    }

    &:hover {
        background-color: #0056b3;
    }
}
</style>
